<template>

 <b-row class="mt-3 mb-3">
    <b-col class="h-100 d-flex flex-column" xs=12 md=6>
      <p>Folders:</p>
      <b-list-group class="fileslist">
        <b-list-group-item button class="d-flex justify-content-between align-items-center"
          v-for="folder in orderedFolders" v-bind:key="folder.name"
          :active="folder===selectedFolder"
          @click="folderSelected(folder)"
          >
          <b-col><small>{{ folder.displayName }}</small></b-col>
          <b-col><small>{{ new Date(folder.timestamp).toLocaleString() }}</small></b-col>
          <b-col><b-badge pill class="bg-primary">{{ Object.keys(folder.files).length }}</b-badge></b-col>
        </b-list-group-item>
      </b-list-group>
    </b-col>

    <b-col xs=12 md=6>
      <p>Files:</p>
      <b-list-group class="fileslist">
        <b-list-group-item class="d-flex justify-content-between align-items-center"
          v-for="file in selectedFolder.files" v-bind:key="file.path"
          >
          <b-col><small>{{ file.path }}</small></b-col>
          <b-col><small>{{ file.size }}&nbsp;&nbsp;&nbsp;&nbsp;{{ new Date(file.timestamp).toLocaleString() }}</small></b-col>
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>
<script>
export default {
  data: function() {
    return {
      //selectedFolder: {
       // name: null,
       // files: []
     // }
    }
  },
  props: {
    selectedFolder: Object,
    folders: {
      type: Array,
      default: null
    }
  },

  computed: {
    orderedFolders: function () {
      if (!this.folders) {
      return []
      } else {
        const foldersCopy = this.folders.concat()
        return foldersCopy.sort((a, b) => {
          return b.timestamp - a.timestamp
        })
      }
    }
  },

  methods: {
    folderSelected: function(folder) {
      // Display the list of files in this folder...
      this.selectedFolder = folder
      this.$emit('select-folder', folder)
    }
  }
};
</script>
<style>
.fileslist {
  border: solid 1px lightgrey;
  min-height: 222px;
  max-height: 222px;
  overflow-y: auto;
}
</style>