<template>
  <div>
    <b-container>
      <h1 class="mt-3">Stage 2</h1>
      <h3>Map Short Reads onto Reference Genomes</h3>
      <div class="text-left">
        <p>At this stage, the trimmed RNA-seq reads are mapped to genome reference (user provided) using STAR. The two-pass mode is used to increase the sensitivity of splice junction discovery. Users can click and visualise the read mapping reports in the Track Existing Jobs page.</p>
        <p>Parameter settings:
          <ul>
            <li>Minimum Intron Size: Minimum size allowed for introns. Default 60 for plants.</li>
            <li>Maximum Intron Size: Maximum size allowed for introns. Default 6000 for Arabidopsis</li>
            <li>Permitted Mapping Mismatches: Maximum mismatches allowed for the read mapping.</li>
          </ul>
        </p>
      </div>
  
      <b-row><b-col>
        <p class="m-0 font-weight-bold">Select the folder which contains the relevant trimming results:</p>
        <b-alert show variant="danger" v-if=errorMsg>{{ errorMsg }}
          <div v-if=(errorShowRefresh)>Please refresh this page and try again, or contact an administrator if you continue to see this error.</div>
        </b-alert>
      </b-col></b-row>
      </b-container>
      <b-container>
      <b-form @submit.prevent="submitJob">
        <folder-browser :folders="folders" :selectedFolder="selectedFolder"
          @select-folder="folderSelected">
        </folder-browser>
        <p class="m-0 text-left">Notes:
          <ul>
            <li>You need to decide the minimum and maximum intron size according to the species you are working on. For example:
              <ul>
                <li>Arabidopsis: minimum intron size 60; maximum intron size 6000</li>
                <li>Barley: minimum intron size 60; maximum intron size 15000</li>
              </ul>
            </li>
            <li>The choice of mis-mapping depending on the quality of the genome reference as well as the similarities between the sequencing samples and the reference genome, e.g. if the RNA-seq reads are from a different cultivar to the genome, a higher number of mismatches might be needed.</li>
          </ul>
</p>
        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Minumum Intron Size:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="minIntronTip">
              <b-form-input id="minIntronInput" v-model="minIntron" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>
          
        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Maximum Intron Size:</b-input-group-text>
            </b-input-group-prepend>

            <div class="input-box" v-b-tooltip.hover.html :title="maxIntronTip">          
              <b-form-input id="maxIntronInput"  v-model="maxIntron" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row> 

        <b-row class="input-row">
        <b-input-group>
          <b-input-group-prepend class="input-label">
            <b-input-group-text class="bg-white w-100">Permitted Mapping Mismatches:</b-input-group-text>
          </b-input-group-prepend>
          
          <div class="input-box" v-b-tooltip.hover.html :title="mismatchesTip">
            <b-form-input id="mismatchesInput"  v-model="mismatches" type="number" min="0" class="bg-secondary"></b-form-input>
          </div>
        </b-input-group>
      </b-row>

      <b-form-checkbox
            id="NameCheck"
            v-model="nameCheck"
            name="NameCheck"
          >
            Tick if you want to use a custom name for the results folder.
          </b-form-checkbox>

          <b-row v-if="nameCheck" class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Custom folder name:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box">
                <b-form-input id="nameInput" v-model="name" type="text" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>

        <b-button type="submit" variant="primary" :disabled="notOKToRun()">Submit</b-button>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import FolderBrowser from '../components/FolderBrowser.vue'
import axios from 'axios'
import { mapGetters} from 'vuex'

export default {
  name: "mapping",
  components: {
    "folder-browser": FolderBrowser
  },
  data() {
    return {
      // Error message that we want to display (if any)
      errorMsg: null,
      // Should the UI show a 'please refresh' msg when showing the error
      errorShowRefresh: true,
      data: null,
      folders: null,
      selectedFolder: {
        name: null,
        files: []
      },
      minIntron: 60,
      maxIntron: 6000,
      mismatches: 1,
      showMinIntron: false,
      showMaxIntron: false,
      showMismatches: false,
      minIntronTip: "A genomic gap is considered as an intron if its length is greater than or equal to the minimum intron size, otherwise it is considered as deletion.",
      maxIntronTip: "The maximum length to consider at which a genomic gap to be an intron.",
      mismatchesTip: "The maximum number of mismatches allowed for read mapping.",
      nameCheck: false,
      name: ""
    }
  },

  computed: {
    ...mapGetters({token: "token"})
  },

  mounted() {
    axios.get("/folders?stage=sr2",
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.folders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });
  },

  methods: {
      onLoad: function() {
        if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
        {
          this.minIntron = this.$route.params.resubmitData.minIntrons;
          this.maxIntron = this.$route.params.resubmitData.maxIntrons;
          this.mismatches = this.$route.params.resubmitData.mismatches;
          this.folderID = this.$route.params.resubmitData.trimmingFolder;
        
          if(this.folderID !== undefined && this.folderID !== null)
          {
            this.folders.forEach(folder => {
              if((folder.id.localeCompare(this.folderID) == 0))
              {
                this.folderSelected(folder);
              }
            });
          }
        }
      },

    folderSelected: function(folder) {
      this.selectedFolder = folder;
    },

    submitJob: function() {
      var custName = null;
      if(this.nameCheck) {
        custName = this.name;
      }

      axios.get("/shortread/mapping", {
        params: {
          token: this.token,
          trimmingFolderID: this.selectedFolder.id,
          minIntron: this.minIntron,
          maxIntron: this.maxIntron,
          mismatches: this.mismatches,
          name: custName
        },
        headers: {"Content-Type": "application/json"}
        }).then(
          function(response) {
            this.data = response.data;

            this.$router.push({ name: 'tracking', params: { folderID: response.data.mappingFolderID }})

          }.bind(this)
        )
        .catch(error => {
          console.log(error)
          this.errorMsg = "ERROR";
        });
    },

    notOKToRun: function() {
      return this.selectedFolder.files.length === 0 ||
        this.errorMsg != null ||
        this.minIntron == null ||
        this.maxIntron == null ||
        this.mismatches == null;
    }
  }
}
</script>

<style scoped>

.error {
  color: #ff0000;
}
</style>